import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Listbox } from "@headlessui/react";
import { CheckIcon, SearchIcon } from "@heroicons/react/outline";

import {
  useOrg,
  useResourcesWithBackfillStatus,
  useResourceStatuses,
} from "lib/api/hooks";
import { Button } from "lib/lucidez/components/Button";
import { getPlatformForKind, Resource } from "lib/platforms";
import { ResourceStatus } from "lib/api/types";
import { useFullObjectSearch } from "lib/utils";
import ArrowsUpDown from "components/svg/ArrowsUpDown";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { SyncToggle } from "components/platforms/common/SyncToggle";
import { SyncListResourceStatusBadge } from "components/platforms/common/card/ResourceStatusBadge";
import { FriendlyDateTime } from "components/core/FriendlyDateTime";
import HoverPopover from "components/core/HoverPopover";
import { EnvironmentBadge } from "components/platforms/common/card/EnvironmentBadge";

interface Sorting {
  id: string;
  name: string;
}

const sortings: Sorting[] = [
  { id: "alpha-asc", name: "Name (Ascending)" },
  { id: "alpha-desc", name: "Name (Descending)" },
  { id: "created-asc", name: "Created (Ascending)" },
  { id: "created-desc", name: "Created (Descending)" },
];

interface SyncListProps {
  children?: React.ReactNode;
}

interface LocationState {
  state?: {
    shouldShowToast?: boolean;
    toastMessage?: string;
  };
}

export const SyncList = ({ children }: SyncListProps) => {
  const [org] = useOrg({
    initialData: null,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { state }: LocationState = useLocation();

  const [resources, mutate] = useResourcesWithBackfillStatus({
    initialData: undefined,
  });
  const [resourceStatuses] = useResourceStatuses({
    refreshInterval: 5000,
  });

  const queryParams = new URLSearchParams(location.search);
  const kind = queryParams.get("kind");

  useEffect(() => {
    if (!org) {
      return;
    }
    if (kind) {
      return;
    }

    if (
      !org.hasCreatedResource &&
      resources &&
      resources.length === 0 &&
      org.id !== "ef259810-e7c3-4f25-8445-2a7ad94367c5" &&
      org.id !== "176313b3-874f-4038-920f-0a92de6156f0"
    ) {
      navigate("/onboard/2");
    }
  }, [org, resources]);

  const [filter, setFilter] = useState("");
  const [sorting, setSorting] = useState(sortings[3]);

  const filteredResults = useFullObjectSearch(filter, resources ?? []);

  const filteredResources = useMemo(
    () =>
      filter && resources
        ? filteredResults.indexes.map((idx) => (resources as Resource[])[idx])
        : resources,
    [filter, resources, filteredResults]
  );

  const sortedResources = useMemo(
    () =>
      filteredResources
        ? sorting.id.startsWith("alpha")
          ? [...filteredResources].sort((a, b) =>
              sorting.id.endsWith("asc")
                ? a.name.localeCompare(b.name)
                : b.name.localeCompare(a.name)
            )
          : [...filteredResources].sort((a, b) =>
              sorting.id.endsWith("asc")
                ? Date.parse(a.insertedAt) - Date.parse(b.insertedAt)
                : Date.parse(b.insertedAt) - Date.parse(a.insertedAt)
            )
        : undefined,
    [sorting, filteredResources]
  );

  const mutateResource = async (resource: Resource) => {
    await mutate([
      ...resources!.filter((res) => res.id !== resource.id),
      resource,
    ]);
  };

  if (state?.shouldShowToast && state?.toastMessage) {
    toast.success(state.toastMessage, { toastId: "resource-mutate-success" });
  }

  if (!resources || !sortedResources) {
    return <PageLoadingSpinner />;
  }

  return (
    <main className="flex-1 pt-8 pb-12 bg-white">
      <section className="custom-container">
        <div className="flex flex-row items-center justify-between px-4">
          <h1 className=" font-bold text-2xl ml-2">Syncs</h1>
          <Link
            to="/syncs/new"
            onClick={() => {
              if (window && window.analytics) {
                window.analytics.track("Add Sync Started");
              }
            }}
          >
            <Button variant="primary">Add Sync</Button>
          </Link>
        </div>
        <div className="w-3/5 my-6 px-4">
          {resources && resources.length >= 4 && (
            <div className="hidden md:block flex-1">
              <ViewOptions
                filter={filter}
                setFilter={setFilter}
                sorting={sorting}
                setSorting={setSorting}
              />
            </div>
          )}
        </div>
        {sortedResources.length > 0 ? (
          <div className="custom-container flex flex-col mt-12 mb-6">
            <ul className="grid xl:grid-cols-[322px,180px,322px,180px,48px] lg:grid-cols-[258px,180px,258px,180px,48px] md:grid-cols-[172px,164px,172px,124px,32px] px-4 mb-2 font-medium text-gray-500 text-xs">
              <li className=" mr-2">Name</li>
              <li>Status</li>
              <li>Destination</li>
              <li className="text-center">Last Activity</li>
              <li className="text-right">Enabled</li>
            </ul>

            <ol>
              {sortedResources.map((resource) => {
                return (
                  <Sync
                    key={resource.id}
                    mutateResource={mutateResource}
                    resource={resource}
                    resourceStatuses={resourceStatuses}
                  />
                );
              })}
            </ol>
          </div>
        ) : (
          <div className="custom-container flex flex-col items-center mt-12 text-center border rounded py-2">
            No syncs found.
          </div>
        )}
      </section>
      {children}
      <Outlet />
    </main>
  );
};

interface SyncProps {
  mutateResource: (resource: Resource) => any;
  resource: Resource;
  resourceStatuses: ResourceStatus[];
}

const Sync = ({ mutateResource, resource, resourceStatuses }: SyncProps) => {
  const resourceStatus =
    resourceStatuses?.find((status) => status.resourceId === resource.id) ??
    null;

  const platform = getPlatformForKind(resource.kind);

  const isFirstResourceSchema = resource.schema.idx === 1;

  const handleToggleActive = async (active: boolean) => {
    await mutateResource({ ...resource, active });
  };

  const syncContainerClassName =
    "flex items-center grid xl:grid-cols-[322px,180px,322px,180px,48px] lg:grid-cols-[258px,180px,258px,180px,48px] md:grid-cols-[172px,164px,172px,124px,32px] mb-4 border-2 border-gray-100 rounded-lg p-4 hover:bg-cool-gray-50 text-xs text-gray-500";

  return (
    <li key={resource.id}>
      <Link
        to={`syncs/${resource.permaslug}/collections`}
        className={syncContainerClassName}
      >
        <div className="flex items-center mr-2">
          <div>
            <div className="flex border h-7 w-7 justify-center items-center rounded-full border-gray-200 hover:bg-cool-gray-200">
              <HoverPopover
                containerProps={{
                  placement: "bottom-start",
                }}
                content={platform.displayName}
              >
                <span className="transition-all text-current cursor-pointer">
                  <platform.displayIcon className="h-4 w-4 text-cool-gray-700" />
                </span>
              </HoverPopover>
            </div>
          </div>
          <HoverPopover
            containerProps={{
              placement: "top",
            }}
            content={resource.name}
          >
            <h3 className="ml-2 font-bold text-lg text-black whitespace-nowrap overflow-hidden overflow-ellipsis">
              {resource.name}
            </h3>
          </HoverPopover>
          <EnvironmentBadge className="ml-2" resource={resource} size="sm" />
        </div>
        <div>
          <SyncListResourceStatusBadge
            isActive={resource.active}
            resourceStatus={resourceStatus}
            isFirstBackfillRunning={
              resource.isBackfilling && isFirstResourceSchema
            }
          />
        </div>
        <div>{destinationRenderer(resource.database)}</div>
        <div className="text-center">
          {resourceStatus?.lastActivityAt ? (
            <>
              <FriendlyDateTime iso8601={resourceStatus.lastActivityAt} /> ago
            </>
          ) : (
            "---"
          )}
        </div>
        <div className="text-right">
          <SyncToggle
            resource={resource}
            refreshResource={handleToggleActive}
          />
        </div>
      </Link>
    </li>
  );
};

const destinationRenderer = (
  database: Resource["database"]
): React.ReactElement => {
  const isSequinHosted = database.definition.type === "shared_postgres";

  if (isSequinHosted) {
    if (database.name) {
      return (
        <HoverPopover
          containerProps={{
            placement: "top",
          }}
          content={database.name}
        >
          <div className="w-full max-h-4 flex flex-row">
            <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
              {database.name}
            </div>{" "}
            <span className="whitespace-nowrap ">| Sequin-hosted</span>
          </div>
        </HoverPopover>
      );
    }

    return (
      <HoverPopover
        containerProps={{
          placement: "top",
        }}
        content="Sequin Demo Database"
      >
        <div className="w-full max-h-4 flex flex-row">
          <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
            Sequin Demo Database
          </div>{" "}
          <span className="whitespace-nowrap ">| Sequin-hosted</span>
        </div>
      </HoverPopover>
    );
  }

  return (
    <HoverPopover
      containerProps={{
        placement: "top",
      }}
      content={database.name}
    >
      <div className="w-full max-h-4 flex flex-row">
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {database.name}
        </div>{" "}
        <span className="whitespace-nowrap ">| Self-hosted</span>
      </div>
    </HoverPopover>
  );
};

interface FilterBoxProps {
  filter: string;
  setFilter: (filter: string) => void;
}

interface SortBoxProps {
  sorting: Sorting;
  setSorting: (sorting: Sorting) => void;
}

type ViewOptionsProps = FilterBoxProps & SortBoxProps;

const ViewOptions = React.memo(
  ({ filter, setFilter, sorting, setSorting }: ViewOptionsProps) => {
    return (
      <div className="flex border-b border-gray-400 items-center flex-1">
        <FilterBox filter={filter} setFilter={setFilter} />
        <SortBox sorting={sorting} setSorting={setSorting} />
      </div>
    );
  }
);
ViewOptions.displayName = "ViewOptions";

const FilterBox = React.memo(({ filter, setFilter }: FilterBoxProps) => (
  <div className="flex flex-row flex-1">
    <div className="w-10 h-10 flex items-center justify-center">
      <SearchIcon className="h-5 text-gray-500" />
    </div>
    <input
      className="focus:outline-none rounded w-full bg-transparent"
      placeholder="Search syncs"
      value={filter}
      onChange={({ target: { value } }) => setFilter(value)}
    />
  </div>
));
FilterBox.displayName = "FilterBox";

const SortBox = React.memo(({ sorting, setSorting }: SortBoxProps) => {
  return (
    <div className="ml-4">
      <Listbox value={sorting} onChange={setSorting}>
        <Listbox.Label className="sr-only">Sort&nbsp;by</Listbox.Label>
        <div className="relative">
          <Listbox.Button className="h-full flex items-center">
            <ArrowsUpDown className="h-5 text-gray-500" />
            <span className="sr-only">{sorting.name}</span>
          </Listbox.Button>
          <Listbox.Options className="absolute right-0 py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
            {sortings.map((sorting, sortingIdx) => (
              <Listbox.Option
                key={sortingIdx}
                className={({ active }) =>
                  `${active ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                              cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={sorting}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`${
                        selected ? "font-medium" : "font-normal"
                      } block truncate`}
                    >
                      {sorting.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
});
SortBox.displayName = "SortBox";
